/* .career-accordion {
    /* background: url(../Assets/Images/AboutUs/career-bg-img.svg) no-repeat bottom left;
    background-size: 400px; */


.career-accordion .accordion-item {
    border: 1px solid #E3DBD8;
    color: #727272;
    background: transparent;
    margin-bottom: 20px;
    border-radius: 20px;
}

.career-accordion .accordion-item h4 {
    color: #B41B05;
    font-size: 18px;
}

.back-to-top {
    position: fixed;
    bottom: 90px;
    right: 50px;
    background-color: #ff4d4d; /* Red theme color */
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.7;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1000;
}

.back-to-top:hover {
    opacity: 1;
    transform: scale(1.1);
}





.accordion-header {
    margin-bottom: 0;
}

.career-accordion .accordion-item .accordion-button:not(.collapsed)::after {
    background: url(../Assets/Images/Tech/Icons/up-arrow.svg) no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

.career-accordion .accordion-item .accordion-button {
    background: transparent;
    color: #727272;
    font-size: 17px;
    font-weight: 700;
    box-shadow: none;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}

.swiper-horizontal {
    touch-action: pan-y;
}

*,
::after,
::before {
    box-sizing: border-box;
}

element.style {
    transition-duration: 0ms;
    transform: translate3d(-969px, 0px, 0px);
    transition-delay: 0ms;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}

.swiper .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}

@media (max-width: 1200px) {
    .career-accordion {
        background: #fff !important;
    }
}

.back {
    background-color: #f7f7f7;
    transform: rotateY(180deg);
    padding: 20px;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    border-radius: 15px;
}

.back h6 {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px; /* Add space between elements */
}

.back h2 {
    font-size: 1.5em;
    color: #007bff;
    margin-bottom: 10px;
}

.back p {
    font-size: 1em;
    color: #555;
}


.flip-container {
    perspective: 1000px;
    width: 100%;
    height: 350px; /* Ensure a fixed height for the flip effect */
    position: relative;
    overflow: hidden; /* Prevents the content from spilling over */
    margin: 0 auto; /* Centers the flip container */
}

.flipper {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    
}

.flip-container:hover .flipper,.flip-container.flipped .flipper {
    transform: rotateY(180deg);
}

.front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    overflow: hidden;
}

.front {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-list li {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px; /* Adjust the space between list items */
}

.inside-carrer{
    color: black;
    
}

.inside-carrer-mail{
    color: black;
    
}

.inside-carrer-mail-telugu{
    color: black;
    
}


@media (max-width: 800px) {
 
.flip-container {
    width: 100%;
    height: 370px; /* Ensure a fixed height for the flip effect */
    position: relative;
    overflow: hidden; /* Prevents the content from spilling over */
    margin: 0 auto; /* Centers the flip container */
    
}

.flipper {
    position: relative;
    width: 100%;
    height: 90%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}


.back-to-top {
    
    bottom: 50px;
    right: 20px;
    z-index: 1000;
}

.hide-on-mobile {
    display: none;
}

.contact-icon{
    width: 15px;
    height: 15px;
    position: relative;
    top: 30px;
    left: 35px;
}

.contact-icon-mail{
    width: 15px;
    height: 15px;
    position: relative;
    left:35px;
    bottom: 2px
}

.language-label{
    font-weight: bold;
    font-size: 15px;
}

.inside-carrer{
    color: black;
    position: relative;
    left: 20%;
    
}

.inside-carrer-mail{
    color: black;
    position: relative;
    bottom: 82%;
    left: 25%;
}

.inside-carrer-mail-telugu{
    color: black;
    position: relative;
    bottom: 82%;
    left: 29%;
}

.mail-container{
    padding: 0px 0px 0px 0px !important;
    height: 40px;
}



   
}

@media screen and (max-width: 1000px) and (orientation: landscape) { 

    .flip-container{
        width: 270px !important;
        height: 270px;
    }

    .container{
        max-width: 900px;
    }

    .team-list li {
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 5px; /* Adjust the space between list items */
    }
    
    
    }
    