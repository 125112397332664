.grow-banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}


.spacing {
    margin-top: 69px;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

section {
    display: block;
    unicode-bidi: isolate;
}

.carousel {
    position: relative;
}



.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}

.about-banner .banner {
    height: 220px !important;
    min-height: 320px;
}

.grow-banner .banner {
    display: flex;
    padding: 30px 0;
}

.grow-banner .banner .banner-in {
    margin: auto 0;
    width: 100%;
}

.about-banner .banner h1 {
    margin-top: 30px;
    font-size: 35px;
}

.grow-banner .banner h1 {
    font-size: 60px;
    font-family: "Samsung Sharp Sans";
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 15px;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.breadcrumb-item a {
    color: #fff;
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #fff;
}

.about-banner {

    background: url("../Assets//Images//Banner/banner1.png") #000 no-repeat;
}

.about-banneriftab {
    margin-top: 75px;
    background: url("../Assets//Images//Banner/foundation course banner new.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    position: relative;
    background-position: center;
}

.about-banner_m {
    margin-top: 75px;
    background: url("../Assets//Images//Banner/mediate  course banner new.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    /* position: relative; */
    background-position: center;
}

.about-banner_b {
    margin-top: 75px;
    background: url("../Assets//Images//Banner/beginner course banner new.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    /* position: relative; */
    background-position: center;

}


.about-banner_ie {
    margin-top: 75px;
    background: url("../Assets//Images//Banner/IELTS.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    position: relative;
    background-position: center;

}

.about-banner_i {
    margin-top: 75px;
    background: url("../Assets//Images//Banner/INTERVIEW.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    position: relative;
    background-position: center;

}

.about-banner_p {
    margin-top: 75px;
    background: url("../Assets//Images//Banner/Phonics.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    position: relative;
    background-position: center;

}


.about-banner_s {
    margin-top: 75px;
    background: url("../Assets//Images//Banner/Seed.jpg");
    background-size: cover;
    height: 300px;
    position: relative;
    background-position: center;
}

.about-banner_so {
    margin-top: 75px;
    background: url("../Assets//Images//Banner/speak out course banner new.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    position: relative;
    background-position: center;

}

.relative-container {
    position: relative;
    height: 250px;
}


/* Video Styling */
.banner-video {
    width: 100%;
    height: 300px;
    object-fit: cover;
    position: relative;

}

.banner-video-contactus {
    width: 100%;
    height: 300px;
    position: relative;
    /* transform: scaleX(2.8); */
    object-fit: cover;
}

/* Text Overlay Styling */
.banner-text {
    position: absolute;
    top: 120px;
    left: 90px;
    color: red;
    font-weight: bold;
    z-index: 1;
    font-size: 150px !important;
    /* Use a reasonable value like 150px */
    font-family: "Samsung Sharp Sans", sans-serif;
    white-space: nowrap;
}

.banner-text h1 {
    font-size: 50px;
    color: red;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    margin: 0;
}


.about-banner_mal {

    background: url("../Assets//Images//Banner/malayalam.jpg") #000 no-repeat;
    background-size: cover;

    height: 300px;
    /* position: relative; */
    background-position: center;

}

.about-banner_t {

    background: url("../Assets//Images//Banner/tamil.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    /* position: relative; */
    background-position: center;

}

.about-banner_tel {

    background: url("../Assets//Images//Banner/telugu.jpg") #000 no-repeat;
    background-size: cover;
    height: 300px;
    /* position: relative; */
    background-position: center;

}



.bullet-arrow ul li {
    background: url("../Assets/Images/Tech/Course/bullet-arrow.svg") no-repeat;
    background-size: 20px;
    margin-bottom: 15px;
    list-style-type: none;
    padding-left: 30px;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
    color: #727272;
    font-size: 14px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

.btn-theme {
    height: 45px;
    min-width: 200px;
    border-radius: 30px !important;
    background: #B41B05;

}

.btn-theme:hover {
    background: #f19191;
}


/* Button styles */
.join-now-button {
    display: block;
    margin-top: 25px;
    /* Equivalent to mt-4 */
    margin-left: 200px;
}

/* Image styles inside the button */
.join-now-arrow {
    margin-left: 8px;
    /* Add spacing between text and image */
}


.section-testimonial-2 {
    background: #f3f3f3;
}

/* Custom styles for the Swiper section */
/* .swiper-section {
    
    padding: 40px 20px;
    background-color: #f9f9f9; 
    max-width: 1200px;
    margin: 0 auto; 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
} */

/* Custom styles for Swiper */
.mySwiper {
    padding-top: 50px;
    padding-bottom: 50px;
    /* Adjust height of Swiper */
    width: 120%;
    /* Full width */
    background: transparent;

    display: flex;
    /* Ensure content aligns properly */
    justify-content: center;
    /* Center align vertically */


}

.mySwiper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 320px;
    height: 400px;
    background: #d1ebff;
    /* box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2); */
    filter: blur(4px);
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    border-color: #2196f3;

}

.mySwiper .swiper-slide-active {
    filter: blur(0px);
    background: #fff;
    height: 370px;
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #2196f3;
}


.mySwiper .swiper-slide img {
    height: 100%;
    width: auto;
    /* Ensure the image maintains its aspect ratio */
    object-fit: cover;
    /* Crop and fit the image within the container */
}

.testimonialBox {
    height: 390px;
    position: relative;
    width: 100%;
    padding: 40px;
    overflow: hidden;
    border: 2px solid #2196f3;

    color: #999;
}

.testimonialBox .details {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

/* .testimonialBox:hover {
    transform: translateY(-10px); 
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); 
} */

/* Content inside the testimonial box */
.testimonialBox .content {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonialBox .content p {
    font-size: 20px;
    line-height: 2;
    color: #555;
    /* Dark gray for readability */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Image box styling */
.testimonialBox .details .imgBx {
    width: 100px;
    height: 80px;
    margin: 0 auto 10px;
    overflow: hidden;
    border-radius: 50%;
    /* Circular image */
    border: 4px solid #f0f0f0;
    /* Light border around image */
}

.testimonialBox .details .imgBx img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensure image fits perfectly */
}

/* Name and designation */
.testimonialBox .details h3 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #2196f3;
    line-height: 2em;
}

.testimonialBox .details h3 span {
    font-size: 12px;
    color: #666;
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right {
    background-image: none;
    background: transparent;
}



.img-fluid-adv {
    margin-top: 35px;
    margin-left: 20px;
}




@media (max-width: 800px) {

    .relative-container {
        position: relative;
        height: 190px;
    }

    .banner-video {

        margin-top: 4%;
        object-fit: unset;
        height: 190px !important;
        transform: scaleX(1.3) !important;
        position: relative !important;
        background-position: center;
    }

    .join-now-button {

        margin-left: 20% !important;
    }

    .banner-video-contactus {


        margin-top: 4%;
        object-fit: unset;
        height: 190px !important;
        transform: scaleX(1.3) !important;
        position: relative !important;
        background-position: center;


    }


    .about-banneriftab {

        background: url("../Assets//Images//Banner/foundation course banner new.jpg") #000 no-repeat;
        background-size: cover;
        height: 112px;
        transform: scaleX(1.1);
        position: relative;
        background-position: center;

    }

    .about-banner_m {

        background: url("../Assets//Images//Banner/mediate  course banner new.jpg") #000 no-repeat;
        background-size: cover;
        height: 112px;
        transform: scaleX(1.1);
        /* position: relative; */
        background-position: center;

    }


    .about-banner_b {

        background: url("../Assets//Images//Banner/beginner course banner new.jpg") #000 no-repeat;
        background-size: cover;
        height: 112px;
        transform: scaleX(1.1);
        /* position: relative; */
        background-position: center;

    }


    .about-banner_ie {

        background: url("../Assets//Images//Banner/IELTS.jpg") #000 no-repeat;
        background-size: cover;
        height: 250px;
        position: relative;
        background-position: center;

    }

    .about-banner_i {

        background: url("../Assets//Images//Banner/INTERVIEW.jpg") #000 no-repeat;
        background-size: cover;
        height: 250px;
        position: relative;
        background-position: center;

    }

    .about-banner_p {

        background: url("../Assets//Images//Banner/Phonics.jpg") #000 no-repeat;
        background-size: cover;
        height: 225px;
        position: relative;
        background-position: center;

    }


    .about-banner_s {

        background: url("../Assets//Images//Banner/Seed.jpg");
        background-size: cover;
        height: 225px;
        position: relative;
        background-position: center;
    }

    .about-banner_so {

        background: url("../Assets//Images//Banner/speak out course banner new.jpg") #000 no-repeat;
        background-size: cover;
        height: 112px;
        transform: scaleX(1.1);
        position: relative;
        background-position: center;

    }



    .about-banner_mal {

        background: url("../Assets//Images//Banner/malayalam.jpg") #000 no-repeat;
        background-size: cover;

        height: 225px;
        /* position: relative; */
        background-position: center;

    }

    .about-banner_t {

        background: url("../Assets//Images//Banner/tamil.jpg") #000 no-repeat;
        background-size: cover;
        height: 225px;
        /* position: relative; */
        background-position: center;

    }

    .about-banner_tel {

        background: url("../Assets//Images//Banner/telugu.jpg") #000 no-repeat;
        background-size: cover;
        height: 225px;
        /* position: relative; */
        background-position: center;

    }


    .col-md-3 .pe-3 .img-fluid-adv {
        display: none;
        /* Hides the image */
    }



    .mySwiper {
        padding-top: 50px;
        padding-bottom: 50px;
        /* Adjust height of Swiper */
        width: 100%;
        /* Full width */
        background: transparent;

    }

    .mySwiper .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 200px;
        height: 200px;
        ;
        background: #d1ebff;
        /* box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2); */
        filter: blur(4px);

    }

    .mySwiper .swiper-slide-active {
        filter: blur(0px);
        background: white;
        width: 175px;
        height: 220px;
    }


    .mySwiper .swiper-slide img {
        height: 50px;
        width: 50px;
        /* Ensure the image maintains its aspect ratio */
        object-fit: cover;
        /* Crop and fit the image within the container */
    }

    .testimonialBox {

        position: relative;
        width: 100%;
        padding: 10px;

        color: #999;
    }

    .testimonialBox .details {
        display: flex;
        align-items: center;
        margin-top: 0px;
    }

    /* .testimonialBox:hover {
        transform: translateY(-10px); 
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); 
    } */

    /* Content inside the testimonial box */
    .testimonialBox .content {
        margin-bottom: 0px;
    }

    .testimonialBox .content p {
        font-size: 20px;
        line-height: 1;
        color: #555;
        /* Dark gray for readability */
        margin-bottom: 2px;
    }

    /* Image box styling */
    .testimonialBox .details .imgBx {
        width: 60px;
        height: 60px;
        margin: 0 auto 5px;
        overflow: hidden;
        border-radius: 50%;
        /* Circular image */
        border: 4px solid #f0f0f0;
        margin-bottom: 20px;
        /* Light border around image */
    }

    .testimonialBox .details .imgBx img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Ensure image fits perfectly */
    }

    /* Name and designation */
    .testimonialBox .details h3 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1px;
        color: #2196f3;
        line-height: 1.1em;
    }

    .testimonialBox .details h3 span {
        font-size: 12px;
        color: #666;
    }

    .swiper-3d .swiper-slide-shadow-left,
    .swiper-3d .swiper-slide-shadow-right {
        background-image: none;
        background: transparent;
    }

    .col-md-3 {
        width: 100%;
        /* Make the column take full width */
        margin: 0 auto;
        /* Center the column */
        text-align: center;
        /* Center-align content inside */
    }

    .col-md-3 .pe-3 {
        padding-right: 0;
        /* Remove padding on smaller screens */
        display: flex;
        justify-content: center;
        /* Center the image horizontally */
        align-items: center;
        /* Center the image vertically if needed */
    }

    .col-md-3 img {

        max-width: 100%;
        height: 300px;
        margin-top: 0;
        margin-left: 15px;
    }



    .join-now-button {
        margin-left: 90px;
    }

    .bullet-arrow ul {
        padding-left: 0px;
    }

}

@media screen and (max-width: 1000px) and (orientation: landscape) {


    .about-banneriftab {
        height: 160px;
    }


    .about-banner_m {
        height: 160px;
    }


    .about-banner_b {

        height: 160px;
    }


    .about-banner_ie {
        height: 250px;
    }

    .about-banner_i {
        height: 250px;
    }

    .about-banner_p {

        height: 225px;

    }


    .about-banner_s {

        height: 225px;

    }

    .about-banner_so {

        height: 160px;
    }


    .col-md-4 .pe-3 img {

        max-width: 200%;
        position: relative;
        right: 65%;
        top: 65px;

    }

    .join-now-button {

        margin-left: 12% !important;
    }

    @media screen and (max-width: 680px) and (orientation: landscape) {
        .col-md-4 .pe-3 img {
            max-width: 100%;
            position: relative;
            right: 0%;
            top: 0px;
        }
    }
    


}