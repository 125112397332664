.section-course .course-wrapper {
    background: #fff;
    padding: 60px;
    border-radius: 30px;
}

.section-course h6 {
    font-size: 11px;
    font-weight: 500;
    color: #B41B05;
    text-align: center;
}

.section-course h2 span {
    font-weight: 400;
}

.section-course h2 {
    font-size: 40px;
    font-weight: bold;
    color: #2D194E;
    margin-bottom: 40px;
    text-align: center;
}

.section-course .course-box {
    margin-bottom: 15px;
    border: 1px solid #C8C2C2;
    border-radius: 10px;
    transition: 0.1s;
    position: relative;
    overflow: hidden;
}

.section-course .course-box .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.4s ease;
}

.section-course .course-box .course-details {
    padding: 15px;
}

.section-course .course-box:hover:hover .overlay {
    text-align: start;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #C8C2C2;
    border-radius: 10px;
}


.section-course .course-box .course-details h4 {
    font-size: 16px;
    margin-top: 0;
    color: #B41B05;
    font-weight: 900;
    margin-bottom: 10px;
}

.section-course .course-box .course-details p {
    color: #727272;
    font-size: 13px;
}

.section-course .course-box .course-details a {
    color: #B41B05;
    text-decoration: none;
    font-weight: 600;
}

@media (max-width:1200px) {

    .section-course .course-box .course-details {
        padding: 0px;
    }

    .section-course .course-wrapper {
        background: #fff;
        padding-top: 10px;
        border-radius: 30px;
    }

    .section-course h2 {
        font-size: 25px;
    }

}