.section-contact .tab-content {
    background-color: #fff;
    padding: 30px 30px 0 30px;
    border-radius: 0 0 40px 0;
    position: relative;
    overflow: hidden;
    height: auto;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.section-contact .nav-tabs .nav-link {
    color: #B41B05;
    font-weight: bold;
    padding: 10px 20px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}


.section-contact .nav-tabs .nav-link span {
    padding: 3px 7px;
    border-radius: 5px;
    background: rgb(242, 197, 197);
    color: #B41B05;
    font-size: 20px;
    margin-right: 5px;
}



/* Styles for the contact image */
.contact-image {
    max-height: 300px;
    margin-left: 60px;
}

.custom-contact-image {
    max-height: 300px;
    /* Add more styles if needed for this specific image */
}


.map {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 0px;
}

.map iframe {
    width: 49.5%;
    height: 450px;
    border: 0;
}



@media (max-width:1200px) {

    .map iframe {
        width: 100%;
        margin-bottom: 1%;
    }


    .custom-contact-image {
        margin-left: 20px;
    }
    
    .contact-image {
        
        margin-right: 50px;
    }

    .section-contact .nav-tabs .nav-link {
        
        padding: 10px 10px;
    }

}

@media screen and (max-width: 1000px) and (orientation: landscape) { 

    .contact-image {
        height: auto;
        max-width: 300px;
    }
    
}