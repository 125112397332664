body, .terms-container, .terms-heading, .terms-content p {
    font-family: 'DM Sans', sans-serif;
    line-height: 1.6;
    color: #000; /* Default text color is black */
  }

.terms-container {
    padding: 2rem;
    max-width: 900px;
    margin: 30px auto; /* Added top margin for better spacing */
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: auto; /* Prevents overflow issues */
    margin-top: 100px;
    font-family: 'DM Sans', sans-serif;
  }

  .terms-heading {
    text-align: center;
    font-size: 2rem;
    color: #333; 
    height: 35px;
    font-family: 'DM Sans', sans-serif;
  }

  .terms-heading span {
    color: red; /* Red for "Conditions" */
     /* Underline "Conditions" */
     
     font-family: 'DM Sans', sans-serif;
  }


  .custom-underline {
    width: 10%; /* Width of the underline */
    height: 2px; /* Thickness of the underline */
    background-color: red; /* Red color for the underline */
    margin: 0px auto 0; /* Centered underline with 10px gap */
    text-align: center;
    
  }

  
  .company-name {
    margin-top: 10px;
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 1rem;
    font-family: 'DM Sans', sans-serif;
  }
  
  .intro {
    font-size: 1rem;
    color: #555;
    margin-bottom: 2rem;
    text-align: justify;
    font-family: 'DM Sans', sans-serif;
  }
  
  .terms-content h2 {
    font-size: 1.2rem;
    color: #222;
    margin-top: 1.5rem;
    font-family: 'DM Sans', sans-serif;
  }
  
  .terms-content p {
    font-size: 0.95rem;
    color: #444;
    margin: 0.5rem 0;
    text-align: justify;
    font-family: 'DM Sans', sans-serif;
  }
  
  .conclusion {
    font-size: 1rem;
    color: #222;
    margin-top: 2rem;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
  }


  