body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Brittany Signature';
  src: url('../src/Assets/fonts/BrittanySignature.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.brittany-text {
  font-family: 'Brittany Signature', cursive;
  font-size: 40px;
  font-weight: bold;
}


@font-face {
  font-family: 'Gotham Ultra';
  src: url('../src/Assets/fonts/FontsFree-Net-gotham-ultra.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.gotham-ultra {

  font-family: 'Gotham Ultra', sans-serif;


  font-size: 60px;
  font-weight: 900;
  color: #333;
  margin-left: 50px;
  margin-top: 30px;
}

.gotham-ultra-1 {
  font-family: 'Gotham Ultra', sans-serif;
  color: #B41B05;
  font-weight: 900;
  font-size: 55px;
  margin-left: 15px;
  position: relative;
  bottom: 45%;
}

@media (min-width: 375px) and (max-width:767px) {


  .gotham-ultra {
    font-size: 35px;
    margin-left: 0px;
    margin-top: 12%;
    text-align: center;
    justify-items: center;
  }

  .brittany-text {
    font-family: 'Brittany Signature', cursive;
    font-size: 22px;
    font-weight: bold;
  }

  .gotham-ultra-1 {
    font-size: 35px;
    margin-left: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    bottom: 60%;
  }

}

@media (min-width: 767px) and (max-width: 1025px) {

  .gotham-ultra {

    font-size: 49px;
    font-weight: 900;
    margin-left: 50px;
    margin-top: 30px;
  }

  .gotham-ultra-1 {
    font-weight: 900;
    font-size: 47px;
    margin-left: 15px;
    position: relative;
    bottom: 45%;
  }

  .brittany-text {
    font-size: 30px;
    font-weight: bold;
  }


}