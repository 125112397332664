.english-content {
    transform: translateY(-60px);
}


.custom-text-blueblack {

    font-size: 50px;
    font-weight: 900;
    color: #333;
    margin-left: 50px;
    margin-top: 30px;


}

.with-confidence {
    display: inline-flex;
    gap: 8px;
    margin-left: 55px;
    height: 60px;
}

.great-vibes-font {
    font-family: "Great Vibes", cursive;
    color: black;
    margin-top: 1px;
    font-size: 26px;
}

.confidence-text {
    color: #B41B05;
    font-weight: 900;
    font-size: 42px;
    margin-left: 15px;
    position: relative;
    bottom: 22%;
}

.another-line {
    display: inline-flex;
    gap: 8px;
    margin-left: 55px;
}

.simple-easy-text {
    margin-top: 10px;
    color: #B41B05;
    font-size: 22px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.join-button-now button {
    position: relative;
    transform: translateY(-55px);
    left: 16%;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

}


.banner_link_1 {
    position: absolute;
    bottom: 6%;
    left: 19%;
    transform: translateX(22%);
    width: 27%;
    height: 10%;
    background: linear-gradient(to right, #25d366 50%, #25d366 50%);
    color: #FFF !important;
    border-radius: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    z-index: 9;
    transition: background-color 0.3s ease;
    overflow: hidden;
    /* Prevent content overflow */
}

img.whatsup_1 {
    width: 20px !important;
    height: 20px !important;
}

.banner_link_1 img {
    width: 40px;
    height: 40px;
    margin-left: 6%;
    background-color: #FFF;
    border-radius: 50%;
}

.text_wrapper_1 {
    background-color: #25d366;
    color: #FFF;
    padding: 0% 3% 0% 3%;
    border-radius: 50px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-left: 3%;
    flex-shrink: 0;
    height: 100%;
}


.postion-of-button {

    display: flex;
    align-items: center;
    justify-content: center;

}

.arrow-container {
    display: flex;
    justify-content: center;
}

.arrow-video {
    position: relative;
    transform: translateY(-7px);
    left: 10%;
    width: 100px;
    height: 100px;
}

.video-content {

    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(14%);
}

.landing-video {
    position: relative;
    top: 10%;
    width: 100%;
    max-width: 500px;
    height: auto;
    display: block;
}

.icons-content {
    transform: translateY(-200px);
}

.video-container {
    margin-top: 90px;

    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: nowrap;
}

.video-item {
    text-align: center;
    width: 30%;
}

.video {
    width: 100%;
    max-width: 70px;
    border-radius: 10px;
}

.video-text {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    color: black;
}

.lighter-text {
    font-weight: 300;
}

.centered-section {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.icon-image {
    width: 25px;
    height: 25px;
}

.circle-animation {
    padding: 0px 0px;
    margin-top: -100px;
    transform: translateY(-150px);
}

.outer-circle {
    position: relative;
    width: 420px;
    height: 420px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
}

.inner-circle {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B41B05;
}

.inner-circle img {
    width: 250px;
    height: 250px;
    object-fit: contain;
    border-radius: 50%;
    transition: opacity 2s ease-in-out;
}

.icons-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconinlanding {
    position: absolute;
    font-size: 24px;
    transition: transform 5s ease-in-out;
}

.separators-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.separator {
    position: absolute;
    width: 0.7px;
    height: 50px;
    background-color: #B41B05;
    transition: transform 5s ease-in-out;
}


.red-container {
    position: relative;
    width: 100vw;
    height: 175px;
    background-color: #B41B05;
    z-index: -1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    color: white;
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    transform: translateY(170%);

}

.red-text-left,
.red-text-right {
    width: 30%;
    text-align: center;
    opacity: 1;
    transition: opacity 3s ease-in-out;
}

.section-testimonial-1 {
    background: white;
    margin-top: 0px;
}



@media (min-width: 377px) and (max-width:767px) {

    .english-content {
        transform: translateY(0px);
    }

    .with-confidence {
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-left: 0px;
        height: 30px;
    }

    .another-line {
        display: flex;

        gap: 8px;
        justify-content: center;
        margin-left: 0px;
    }

    .simple-easy-text {
        margin-top: 1% !important;
        font-size: 18px;
        font-weight: 500;

    }

    .landing-video {
        top: 29%;
        width: 100%;
        max-width: 70%;
    }

    .simple-easy-text {
        margin-top: 10px;
        font-size: 15px;
        font-weight: 500;

    }

    .arrow-video {
        position: absolute;
        left: 62%;
        width: 80px;
        height: 80px;
    }


    .join-button-now button {

        transform: translateY(30px);
        left: 20%;
        background-color: blue;
        color: white;
        font-size: 16px;
        font-weight: bold;
        padding: 2px 6px;
        border-radius: 5px;
        margin-right: 60px;
    }


    .banner_link_1 {
        position: absolute;

        bottom: -38%;

        left: 23%;
        transform: translateX(22%);
        width: 32%;
        height: 15%;
        background: linear-gradient(to right, #25d366 50%, #25d366 50%);
        color: #FFF !important;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        z-index: 9;
        transition: background-color 0.3s ease;
        overflow: hidden;

    }

    img.whatsup_1 {
        width: 16px !important;
        height: 16px !important;
    }

    .banner_link_1 img {
        width: 40px;
        height: 40px;
        margin-left: 10%;
        background-color: #FFF;
        border-radius: 50%;
    }

    .text_wrapper_1 {
        background-color: #25d366;
        color: #FFF;
        padding: 0% 3% 0% 3%;
        border-radius: 50px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        margin-left: 3%;
        flex-shrink: 0;
        height: 100%;
    }


    .video-content {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(30%);
    }

    .icons-content {
        transform: translateY(-200px);
    }


    .video-container {
        margin-top: 90%;

        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        flex-wrap: nowrap;
        padding: 0% 5%;
    }

    .video-item {
        text-align: center;
        width: 30%;
    }

    .video {
        width: 100%;
        max-width: 60px !important;
        border-radius: 10px;
    }

    .video-text {
        font-size: 12px !important;
        font-weight: bold;
        margin-top: 10px;
        color: black;
    }


    .circle-animation {
        padding: 0px 0px;
        margin-top: 0px;
        transform: translateY(-200px);
    }

    .centered-section {
        min-height: 35vh;
    }


    .icon-image {
        width: 15px;
        height: 15px;
    }


    .outer-circle {
        width: 200px;
        height: 200px;
    }


    .inner-circle {
        width: 100px;
        height: 100px;
    }

    .inner-circle img {
        width: 100px;
        height: 100px;
    }

    .icons-container {

        width: 100%;
        height: 100%;

    }


    .iconinlanding {
        position: absolute;
        font-size: 24px;
        transition: transform 5s ease-in-out;

    }


    .separators-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }

    .separator {
        position: absolute;
        width: 0.7px;
        height: 25px;
        background-color: #B41B05;
        transition: transform 5s ease-in-out;
    }

    /* Red Container */
    .red-container {
        position: relative;
        left: 0;
        padding: 0 5vw;
        font-size: 9px;
        width: 100vw;
        height: 80px;
        padding: 0 5vw;
        transform: translateY(175%);
    }

    .red-text-left,
    .red-text-right {
        width: 20%;
        text-align: center;
        opacity: 1;
        transition: opacity 3s ease-in-out;
        word-break: keep-all;
        overflow-wrap: break-word;
        white-space: normal;
    }


    .section-testimonial-1 {

        margin-top: -150px;
    }


}

@media (max-width: 376px) {

    .english-content {
        transform: translateY(0px);
    }

    .with-confidence {
        display: flex !important;
        justify-content: center !important;
        gap: 8px !important;
        margin-left: 0px !important;
        height: 30px !important;
    }

    .another-line {
        display: flex !important;

        gap: 8px !important;
        justify-content: center !important;
        margin-left: 0px !important;
    }

    .simple-easy-text {
        margin-top: 1% !important;
        font-size: 18px !important;
        font-weight: 500 !important;

    }



    .simple-easy-text {
        margin-top: 10px !important;
        font-size: 15px !important;
        font-weight: 500 !important;

    }

    .arrow-video {
        position: absolute !important;
        left: 62% !important;
        width: 80px !important;
        height: 80px !important;
    }


    .join-button-now button {

        transform: translateY(30px) !important;
        left: 20% !important;
        background-color: blue !important;
        color: white !important;
        font-size: 16px !important;
        font-weight: bold !important;
        padding: 2px 6px !important;
        border-radius: 5px !important;
        margin-right: 60px !important;
    }



    .banner_link_1 {
        position: absolute;

        bottom: -38%;

        left: 19%;
        transform: translateX(22%);
        width: 35%;
        height: 15%;
        background: linear-gradient(to right, #25d366 50%, #25d366 50%);
        color: #FFF !important;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        z-index: 9;
        transition: background-color 0.3s ease;
        overflow: hidden;

    }

    img.whatsup_1 {
        width: 16px !important;
        height: 16px !important;
    }

    .banner_link_1 img {
        width: 40px;
        height: 40px;
        margin-left: 10%;
        background-color: #FFF;
        border-radius: 50%;
    }

    .text_wrapper_1 {
        background-color: #25d366;
        color: #FFF;
        padding: 0% 3% 0% 3%;
        border-radius: 50px;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        margin-left: 3%;
        flex-shrink: 0;
        height: 100%;
    }


    .video-content {

        transform: translateY(30%);
    }

    .video-container {
        margin-top: 85% !important;

        margin-left: 0px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 30px !important;
        padding: 0% 5% !important;
        flex-wrap: nowrap !important;

    }


    .landing-video {
        top: 38% !important;
        width: 100% !important;
        max-width: 70% !important;
    }


    .video-item {
        text-align: center;
        width: 30%;
        /* Ensures all three fit in one row */
    }

    .video {
        width: 100%;
        max-width: 60px !important;
        border-radius: 10px;
    }

    .video-text {
        font-size: 12px !important;
        font-weight: bold;
        margin-top: 10px;
        color: black;
    }

    .centered-section {
        min-height: 35vh !important;
    }


    .icon-image {
        width: 15px !important;
        height: 15px !important;
    }


    .outer-circle {
        width: 200px !important;
        height: 200px !important;
    }


    .inner-circle {
        width: 100px !important;
        height: 100px !important;
    }

    .inner-circle img {
        width: 100px !important;
        height: 100px !important;
    }

    /* Icons Container */
    .icons-container {

        width: 100%;
        height: 100%;

    }


    .iconinlanding {
        position: absolute;
        font-size: 24px !important;
        transition: transform 5s ease-in-out;
        /* Smooth Movement */
    }

    /* Separators */
    .separators-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }

    .separator {
        position: absolute;
        width: 0.7px !important;
        height: 25px !important;
        background-color: #B41B05;
        transition: transform 5s ease-in-out;
    }

    /* Red Container */
    .red-container {
        position: relative !important;
        padding: 0 3vw !important;
        font-size: 9px !important;
        width: 100vw !important;
        height: 80px !important;
        transform: translateY(175%) !important;
    }

    .red-text-left,
    .red-text-right {
        width: 20% !important;
        text-align: center;
        opacity: 1;
        transition: opacity 3s ease-in-out;
        word-break: keep-all !important;
        overflow-wrap: break-word !important;
        white-space: normal !important;
    }

    .section-testimonial-1 {

        margin-top: -130px !important;
    }

}

@media (min-width: 767px) and (max-width: 1023px) {

    .english-content {
        transform: translateY(5px);
    }

    .custom-text-blueblack {

        font-size: 50px;
        font-weight: 900;
        color: #333;
        margin-left: 50px;
        margin-top: 30px;
    }

    .with-confidence {
        display: inline-flex;
        gap: 8px;
        margin-left: 55px;
        height: 60px;
    }

    .great-vibes-font {
        font-family: "Great Vibes", cursive;
        color: black;
        margin-top: 1px;
        font-size: 26px;
    }

    .confidence-text {
        color: #B41B05;
        font-weight: 900;
        font-size: 42px;
        margin-left: 15px;
        position: relative;
        bottom: 22%;
    }

    .another-line {

        display: flex;
        gap: 8px;
        margin-left: 5px;
    }


    .simple-easy-text {
        position: relative;
        left: 46px;
        margin-top: 10px;
        color: #B41B05;
        font-size: 20px;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
        transform: translateY(-25px);
    }

    .join-button-now button {
        position: relative;
        top: 19%;
        left: 26%;
        background-color: blue;
        color: white;
        font-size: 16px;
        font-weight: bold;
        padding: 2px 6px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        margin-right: 170px;
    }



    .postion-of-button {


        display: flex;
        align-items: center;


    }



    .banner_link_1 {
        position: absolute;
        bottom: -18%;
        left: 13.5%;
        transform: translateX(22%);
        width: 21%;
        height: 13%;
        background: linear-gradient(to right, #25d366 50%, #25d366 50%);
        color: #FFF !important;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        z-index: 9;
        transition: background-color 0.3s ease;
        overflow: hidden;
        /* Prevent content overflow */
    }

    img.whatsup_1 {
        width: 18px !important;
        height: 18px !important;
    }

    .banner_link_1 img {
        width: 40px;
        height: 40px;
        margin-left: 6%;
        background-color: #FFF;
        border-radius: 50%;
    }

    .text_wrapper_1 {
        background-color: #25d366;
        color: #FFF;
        padding: 0% 3% 0% 3%;
        border-radius: 50px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        margin-left: 3%;
        flex-shrink: 0;
        height: 100%;
    }


    .arrow-container {
        display: flex;
        justify-content: center;

    }

    .arrow-video {
        position: absolute;
        transform: translateY(-30px);
        left: 39.5%;
        margin-top: 0px;
        width: 80px;
        height: 80px;
    }

    .video-content {

        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(14%);
    }

    .landing-video {
        position: absolute;
        transform: translateY(-140px);
        /* top: 100%; */
        right: 2%;
        width: 100%;
        max-width: 350px;
        height: auto;
        display: block;
    }

    .video-container {
        margin-top: 0px;

        margin-left: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        /* Space between videos */
        flex-wrap: nowrap;
        /* PREVENTS videos from stacking */
    }

    .video-item {
        text-align: center;
        width: 30%;
        /* Ensures all three fit in one row */
    }

    .video {
        width: 100%;
        max-width: 50px;
        border-radius: 10px;
    }

    .video-text {
        font-size: 16px;
        font-weight: bold;
        margin-top: 5px;
        color: black;
    }

    .icons-content {
        transform: translateY(80px);
    }

    .lighter-text {
        font-weight: 300;
    }

    .centered-section {
        min-height: 40vh;
    }

    .circle-animation {
        padding: 0px 0px;
        margin-top: -100px;
        transform: translateY(150px);
    }

    .icon-image {
        width: 25px;
        height: 25px;
    }


    .outer-circle {
        position: relative;
        width: 420px;
        height: 420px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }


    .inner-circle {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #B41B05;
    }

    .inner-circle img {
        width: 250px;
        height: 250px;
        object-fit: contain;
        border-radius: 50%;
        transition: opacity 2s ease-in-out;
    }


    .icons-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .iconinlanding {
        position: absolute;
        font-size: 24px;
        transition: transform 5s ease-in-out;

    }


    .separators-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }

    .separator {
        position: absolute;
        width: 0.7px;
        height: 50px;
        background-color: #B41B05;
        transition: transform 5s ease-in-out;
    }

    .red-container {
        position: relative;
        width: 100vw;
        height: 175px;
        background-color: #B41B05;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 3vw;
        color: white;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        transform: translateY(170%);    
    }

    .red-text-left,
    .red-text-right {
        width: 20%;
        text-align: center;
        opacity: 1;
        transition: opacity 3s ease-in-out;
    }

    .section-testimonial-1 {
        background: white;
        margin-top: 300px;
    }



}


@media (min-width: 1024px ) and (max-width: 1025px) {

    .english-content {
        transform: translateY(5px);
    }

    .custom-text-blueblack {

        font-size: 50px;
        font-weight: 900;
        color: #333;
        margin-left: 50px;
        margin-top: 30px;
    }

    .with-confidence {
        display: inline-flex;
        gap: 8px;
        margin-left: 55px;
        height: 60px;
    }

    .great-vibes-font {
        font-family: "Great Vibes", cursive;
        color: black;
        margin-top: 1px;
        font-size: 26px;
    }

    .confidence-text {
        color: #B41B05;
        font-weight: 900;
        font-size: 42px;
        margin-left: 15px;
        position: relative;
        bottom: 22%;
    }

    .another-line {

        display: flex;
        gap: 8px;
        margin-left: 5px;
    }


    .simple-easy-text {
        position: relative;
        left: 46px;
        margin-top: 10px;
        color: #B41B05;
        font-size: 20px;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
        transform: translateY(-25px);
    }

    .join-button-now button {
        position: relative;
        top: 19%;
        left: 26%;
        background-color: blue;
        color: white;
        font-size: 16px;
        font-weight: bold;
        padding: 2px 6px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        margin-right: 170px;
    }



    .postion-of-button {


        display: flex;
        align-items: center;


    }



    .banner_link_1 {
        position: absolute;
        bottom: -19%;
        left: 10.5%;
        transform: translateX(22%);
        width: 16%;
        height: 15%;
        background: linear-gradient(to right, #25d366 50%, #25d366 50%);
        color: #FFF !important;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        z-index: 9;
        transition: background-color 0.3s ease;
        overflow: hidden;
        /* Prevent content overflow */
    }

    img.whatsup_1 {
        width: 18px !important;
        height: 18px !important;
    }

    .banner_link_1 img {
        width: 40px;
        height: 40px;
        margin-left: 6%;
        background-color: #FFF;
        border-radius: 50%;
    }

    .text_wrapper_1 {
        background-color: #25d366;
        color: #FFF;
        padding: 0% 3% 0% 3%;
        border-radius: 50px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        margin-left: 3%;
        flex-shrink: 0;
        height: 100%;
    }


    .arrow-container {
        display: flex;
        justify-content: center;

    }

    .arrow-video {
        position: absolute;
        transform: translateY(-30px);
        left: 30.4%;
        margin-top: 0px;
        width: 80px;
        height: 80px;
    }

    .video-content {

        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(14%);
    }

    .landing-video {
        position: absolute;
        transform: translateY(-140px) !important;
        left: 115%;
        width: 100%;
        max-width: 380px;
        height: auto;
        display: block;
    }

    .video-container {
        margin-top: 0px;

        margin-left: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        /* Space between videos */
        flex-wrap: nowrap;
        /* PREVENTS videos from stacking */
    }

    .video-item {
        text-align: center;
        width: 30%;
        /* Ensures all three fit in one row */
    }

    .video {
        width: 100%;
        max-width: 50px;
        border-radius: 10px;
    }

    .video-text {
        font-size: 16px;
        font-weight: bold;
        margin-top: 5px;
        color: black;
    }

    .icons-content {
        transform: translateY(80px);
    }

    .lighter-text {
        font-weight: 300;
    }

    .centered-section {
        min-height: 40vh;
    }

    .circle-animation {
        padding: 0px 0px;
        margin-top: -100px;
        transform: translateY(150px);
    }

    .icon-image {
        width: 25px;
        height: 25px;
    }


    .outer-circle {
        position: relative;
        width: 420px;
        height: 420px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }


    .inner-circle {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #B41B05;
    }

    .inner-circle img {
        width: 250px;
        height: 250px;
        object-fit: contain;
        border-radius: 50%;
        transition: opacity 2s ease-in-out;
    }


    .icons-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .iconinlanding {
        position: absolute;
        font-size: 24px;
        transition: transform 5s ease-in-out;

    }


    .separators-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }

    .separator {
        position: absolute;
        width: 0.7px;
        height: 50px;
        background-color: #B41B05;
        transition: transform 5s ease-in-out;
    }

    .red-container {
        position: relative;
        width: 100vw;
        height: 175px;
        background-color: #B41B05;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 7vw;
        color: white;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        transform: translateY(170%);    
    }

    .red-text-left,
    .red-text-right {
        width: 20%;
        text-align: center;
        opacity: 1;
        transition: opacity 3s ease-in-out;
    }

    .section-testimonial-1 {
        background: white;
        margin-top: 250px;
    }



}