.dropdown-item:active {
    background-color: red!important;
    color: white !important;; /* Background color when clicked */
     
}

.dropdown-item:hover {
    background-color: pink; /* Background color when clicked */
    color:black; /* Optional: Change text color */
}

.logo{
    height: 60px;
}

@media (max-width: 768px){

    .logo{
        height: 30px;
        margin: 17px;
    }
    

}


