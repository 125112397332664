.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px;
    }
}

* {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}




.mainContainer {
    background-image: url('../Assets/Images/Banner/banner1.png');
    margin-top: 90px;
}

.mainContainer_f {
    background-image: url('../Assets/Images/Banner/foundation course banner.jpg');
    margin-top: 90px;
    background-size: 1500px;
    background-position: center;
}

.mainContainer_m {
    background-image: url('../Assets/Images/Banner/Mediate.jpg');
    margin-top: 90px;
    background-size: 1500px;
}

.mainContainer_ie {
    background-image: url('../Assets/Images/Banner/IELTS.jpg');
    margin-top: 90px;
    background-size: 1500px;
}

.mainContainer_s {
    background-image: url('../Assets/Images/Banner/Seed.jpg');
    margin-top: 90px;
    background-size: 1500px;
}

.mainContainer_so {
    background-image: url('../Assets/Images/Banner/Speak\ out.jpg');
    margin-top: 90px;
    background-size: 1500px;
    background-position: center;
}

.mainContainer_i {
    background-image: url('../Assets/Images/Banner/INTERVIEW.jpg');
    margin-top: 90px;
    background-size: 1500px;
    background-position: center;
}

.mainContainer_p {
    background-image: url('../Assets/Images/Banner/Phonics.jpg');
    margin-top: 90px;
    background-size: 1500px;
    background-position: center;
}

.mainContainer_t {
    background-image: url('../Assets/Images/Banner/tamil.jpg');
    margin-top: 90px;
    background-size: 1500px;
    background-position: center;
    position: relative;
    /* Allows absolute positioning inside */
    z-index: 1;
}

.mainContainer_b {
    background-image: url('../Assets/Images/Banner/beginner course banner.jpg');
    margin-top: 90px;
    background-size: 1500px;
    background-position: center;
}

.mainContainer_about {
    position: relative;
    overflow: hidden;
    height: 100vh;
    margin-top: 90px;
}

/* No space between columns */


.mainContainer_about video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}


.mainContainer_tel {
    background-image: url('../Assets/Images/Banner/telugu.jpg');
    margin-top: 90px;
    background-size: 1500px;
    background-position: center;
    position: relative;
    /* Allows absolute positioning inside */
    z-index: 1;
}


.mainContainer_mal {
    background-image: url('../Assets/Images/Banner/malayalam.jpg');
    margin-top: 90px;
    background-size: 1500px;
    background-position: center;
    position: relative;
    /* Allows absolute positioning inside */
    z-index: 1;
}




.align-items-start {

    height: 250px;
    /* Sets the height of the container */
}

.journey-vid {
    position: relative;
    width: 100%;
    /* Stretch across the full width of the page */
    height: auto;
    /* Maintain aspect ratio */
    overflow: hidden;
}

.banner-container {
    position: relative;
    /* To make the social-icons position relative to the banner */
}


.social-icons {

    position: absolute;
    /* Position relative to the nearest positioned ancestor */

    bottom: 10px;
    /* Adjust the distance from the bottom of the banner */
    left: 50%;
    transform: translateX(-50%);
    /* Offset 50% of its width to center align */
    display: flex;

    gap: 50px;
    /* Space between icons */
    z-index: 2;

}

.social-icons a {
    text-decoration: none;
}

.social-icon {
    font-size: 30px;
    /* Adjust icon size */
    color: #fff;
    /* Default white color for visibility */
    transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
    transform: scale(1.2);
    /* Slightly enlarge icon on hover */
    color: #f0a500;
    /* Optional hover color change */
}

.container2 {
    margin-top: 200px;
}

.learnEnglish {
    font-weight: bold;
    font-size: 60px;
}

.anyTime {
    font-size: 60px;
    font-weight: bold;
    color: #B41B05;

}

.mobileGifContainer {
    margin-top: 100px;
}

.mobileGif {
    height: 450px;
}

.theme-red-text {
    color: #B41B05;
}



.section-testimonials {
    padding: 0 0 80px 0;
    background: rgb(255, 240, 240);
}


.section-why-choose-us {
    padding: 10px 0;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-why-choose-us h6 {
    font-size: 11px;
    font-weight: 500;
    color: #B41B05;
}

.section-why-choose-us h2 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #2D194E;
    margin-bottom: 20px;
}



/* .section-why-choose-us .whychoose-box {
    border: 1px solid #E3DBD8;
    border-radius: 8px;
    min-height: 200px;
    padding: 20px;
    margin-bottom: 20px;
} */

.section-why-choose-us .whychoose-box .icon-box {
    margin-bottom: 20px;
}

.section-why-choose-us .whychoose-box h4 {
    color: #B41B05;
    font-weight: bold;
}


.section-faq {
    padding: 10px 0;
    background: #B41B05;
    color: #fff;
}

.section-faq h6 {
    font-size: 11px;
    font-weight: 500;
    color: #fff;
}

.section-faq h2 {
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
}

.section-faq p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    color: #fff;
}

.section-faq .accordion-item {
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    margin-bottom: 20px;
    border-radius: 20px;
}

.section-faq .accordion-item h4 {
    color: #fff;
    font-size: 18px;
}


.section-faq .accordion-item .accordion-button {
    background: transparent;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    box-shadow: none;
}

.collapse:not(.show) {
    display: none;
}

.section-faq .accordion-item .accordion-button::after {
    background: url("../Assets/Images/Tech/Icons/side-arrow.svg") no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
}

.section-faq .accordion-item .accordion-button:not(.collapsed)::after {
    background: url(../Assets/Images/Tech/Icons/up-arrow.svg) no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
}

.light-red-bg {
    background: rgb(255, 240, 240);
}


.section-achievements {
    padding: 80px 0;
}

.section-achievements {
    padding: 80px 0;
}

.section-achievements h6 {
    font-size: 11px;
    font-weight: 500;
    color: #B41B05;
}

.section-achievements h2 {
    font-size: 40px;
    font-weight: bold;
    color: #2D194E;
    margin-bottom: 20px;
}

.section-achievements .partner-box {
    border: 1px solid #E3DBD8;
    border-radius: 8px;
    min-height: 200px;
    padding: 20px;
    margin-bottom: 20px;
}



.section-testimonials .testimonial-box {
    background: #fff;
    padding: 0;
    border-radius: 30px;
}

.section-testimonials h6 {
    font-size: 11px;
    font-weight: 500;
    color: #B41B05;
    text-align: left;
    margin-top: 60px;
}

.section-testimonials .testimonial-box .author h5 {
    color: #B41B05;
    font-weight: bold;
    margin-top: 15px;
}

.section-testimonials .testimonial-box .author p {
    color: #727272;
}

.section-testimonials .testimonial-box .author small {
    font-size: .875em;
    color: #727272;
}

.banner-container {
    margin-top: 6%;
    position: relative;
}

.image-container {
    position: relative;
    /* This makes sure the absolutely positioned elements inside it are relative to this container */
    width: 100%;
    height: auto;
}

/* Video styling */
.image-container img {
    width: 100%;
    height: auto;
}

.banner_link_l {
    position: relative;
    bottom: 5px;
    /* Adjust as needed to place the link near the bottom */
    left: 50%;
    transform: translateX(-50%);
    /* Center the link horizontally */
    background-color: #25d366;
    color: #FFF !important;
    border-radius: 50px;
    text-align: center;
    padding: 3px 3px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    z-index: 9;
    /* Ensure the link is in front of the video */
    transition: opacity 0.3s ease;
    opacity: 1;

}


.banner_link_l img {
    width: 20px;
    /* Size of the WhatsApp icon */
    height: 20px;
    margin-left: 10px;
}


.banner_link {
    position: absolute;
    bottom: 12%;
    left: 21%;
    transform: translateX(-50%);
    width: 27%; /* Fixed width for the banner */
    height: 10%; /* Fixed height for the banner */
    background: linear-gradient(to right, #fff 50%, #25d366 50%);
    color: #FFF !important;
    border-radius: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    z-index: 9;
    transition: background-color 0.3s ease;
    overflow: hidden; /* Prevent content overflow */
}


img.whatsup {
    width: 50px !important;
    height: 50px !important;
}

.banner_link img {
    width: 40px;
    height: 40px;
    margin-left: 6%;
    background-color: #FFF; 
    border-radius: 50%; 
}

.text_wrapper {
    background-color: #25d366;
    color: #FFF;
    padding: 0% 11% 0% 10%;
    border-radius: 50px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap; /* Prevent text wrapping */
    margin-left: 3%;
    flex-shrink: 0; /* Prevent text from shrinking */
    height: 100%; /* Match the height of the icon */
}



.satisfied-box {
    /* background: url("../Assets/Images/Tech/Clients/bg-stisfied.svg") #2D194E no-repeat; */
    background-color: #2D194E;
    background-size: cover;
    padding: 15px;
    border-radius: 15px;
    min-height: 300px;
    margin: -100px auto 0 auto;
    color: #fff;
    padding: 30px;
    z-index: 99;
    position: relative;
    max-width: 800px;
}

.satisfied-box h6 {
    font-size: 11px;
    font-weight: 700;
    color: #fff;
    margin-top: 0;
}

.satisfied-box h2 {
    font-size: 55px;
    /* font-weight: bold; */
    color: #fff;
    margin-bottom: 5px;
}

.satisfied-box .satisfyd-box {
    border: 1px solid #fff;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.satisfied-box .satisfyd-box h3 {
    margin-bottom: 0px;
}

.satisfied-box h3 {
    font-weight: bold;
}

.satisfied-box .satisfyd-box p {
    margin-bottom: 0;
    font-size: 12px;
}

footer {
    padding-top: 70px;
    background-repeat: no-repeat;
    background: #201f1f;
}

.carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    /* Makes the pagination dots round */
    background-color: #B41B05;
    /* Customize the dot color */
    align-items: start;
}

.carousel-indicators .active {
    background-color: #B41B05;
    /* Customize the active dot color */
}


.text-primary {
    color: #615e5d !important;
}

.grow-banner {
    background-color: #DAFFFA;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.grow-banner .banner {
    display: flex;
    min-height: 80vh;
    padding: 30px 0;
}

.grow-banner .banner h1 {
    font-size: 60px;
    font-family: "Samsung Sharp Sans";
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 15px;
}

.grow-banner .banner .banner-in {
    margin: auto 0;
    width: 100%;
}

.onHoveringWhyChoose:hover {
    background-color: aqua;
}

.circle-background {
    width: 40px;
    /* Adjust as needed for the circle size */
    height: 40px;
    /* Adjust as needed for the circle size */
    background-color: white;
    /* White background */
    border-radius: 50%;
    /* Makes it circular */
    display: flex;
    /* Use flexbox for centering */
    align-items: center;
    /* Center the image vertically */
    justify-content: center;
    /* Center the image horizontally */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional: Adds a shadow for better visibility */
    border: 1px solid #ddd;
    /* Optional: Adds a subtle border */
}

.circle-background img {
    width: 18px;
    /* Ensures the image stays within the circle */
    height: 18px;
    /* Ensures the image stays within the circle */
}

/* General styles for the welcome section */
.welcome-section {
    padding: 50px;
    text-align: center;
}

/* Styles for the title */
.title-primary {
    font-size: 40px;
    font-weight: bold;
    color: #2D194E;
}

.title-highlight {
    font-size: 40px;
    font-weight: bold;
    color: #B41B05;
}

/* Styles for the description */
.description {
    font-weight: bold;
    max-width: 600px;
    /* Adjust width as needed */
    margin: 0 auto;
    /* Center the paragraph */
}

.image-wrapper {
    width: 45px;
    height: 45px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Adjust spacing between the icon and text */
}

.animated-text-container {
    font-size: 24px;
    font-weight: bold;
    color: white;
    width: 30%;
    position: absolute;
    bottom: 206px;
    left: 100px;
}

.animated-text {
    opacity: 0;
    position: absolute;
    animation: text-fade 16s infinite; /* Total cycle per text is 16 seconds */
}

/* Delay for each child */
.animated-text:nth-child(1) {
    animation-delay: 0s;
}

.animated-text:nth-child(2) {
    animation-delay: 4s; /* Starts after Text 1 finishes */
}

.animated-text:nth-child(3) {
    animation-delay: 8s; /* Starts after Text 2 finishes */
}

.animated-text:nth-child(4) {
    animation-delay: 12s; /* Starts after Text 3 finishes */
}

/* Keyframes for precise timing */
@keyframes text-fade {
    0%, 12.5% {
        opacity: 0; /* Hidden during the initial delay */
    }
    18.75% {
        opacity: 1; /* Starts fading in slowly */
    }
    31.25% {
        opacity: 1; /* Fully visible for 2 seconds */
    }
    37.5% {
        opacity: 0; /* Starts fading out slowly */
    }
    100% {
        opacity: 0; /* Fully hidden for the rest of the cycle */
    }
}

/* Styles for tablet view */
@media (max-width: 1024px) and (min-width: 768px) {
    .banner-container {
        margin-top: 10%; /* Adjust for tablets */
    }

    .banner_link {
        left: 50% !important;
        width: 40%; 
        height: 10%; 

    }

    img.whatsup {
        width: 20px !important;
        height: 20px !important;
        
    }

    .text_wrapper {
        /* padding: 0% 4% 0% 4%;
        font-size: 11px; */
        margin-left: 1%;
    }

    .animated-text-container {
        
        width: 30%;
        position: absolute;
        top:55%;
        left: 0px;
        margin-left: 7.5%;
        margin-bottom: 25%;
    }
}


@media (max-width: 768px) {

    .section-why-choose-us h2 {
        
        font-size: 27px;
       
    }

    .banner-container {
        margin-top: 80px;
        position: relative;

    }

    .image-container img {
        
        width: 100%;
        height: auto;
        
    }


    .banner_link {
        
        left: 50%;
        width: 40%; 
        height: 10%; 
        bottom: 8%;

    }
    
    
    img.whatsup {
        width: 13px !important;
        height: 13px !important;
        
    }
    
    .banner_link img {
       
        margin-left: 25%;
        background-color: #FFF; 
        border-radius: 50%; 
    }
    
    .text_wrapper {
        background-color: #25d366;
        color: #FFF;
        padding: 0% 4% 0% 4%;
        font-size: 11px;
        font-weight: bold;
        margin-left: 3%;
    }
    
    .animated-text-container {
        font-size: 11px;
        font-weight: bold;
        color: white;
        width: 30%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        margin-left: 7.5%;
        margin-bottom: 18%;
    }


    .welcome-section {
        padding: 30px;

    }





    .satisfied-box {
        background: url("../Assets/Images/Tech/Clients/bg-stisfied.svg") #2D194E no-repeat;
        background-size: cover;
        padding: 15px;
        border-radius: 15px;
        min-height: 300px;
        margin: 10px auto 0 auto;
        color: #fff;
        padding: 30px;
        z-index: 99;
        position: relative;
        max-width: 800px;
    }

}

@media screen and (max-width: 1000px) and (orientation: landscape) {  
    .banner-container {
        margin-top: 78px; 
    }

    .image-container img {
        
        width: 100%;
        height: 100%;
        
    }

    .animated-text-container {
        font-size: 16px;
        font-weight: bold;
        color: white;
        width: 20%;
        position: absolute;
        margin-left: 7.5%;
        margin-bottom: 14%; 
    }

    
    .banner_link {
        
        left: 50%;
        width: 35%; 
        height: 10%; 

    }
    
    
    img.whatsup {
        width: 19px !important;
        height: 19px !important;
        
    }
    
    .banner_link img {
       
        margin-left: 2% ;
        background-color: #FFF; 
        border-radius: 50%; 
    }

    .wrapper_inside{
        height: 120vh !important;
    }

    .wrapper_inside_l {
        height: 120vh !important;
    }

    .container_inside_l{
        height: 70% !important;
    }

} 

@media (min-width: 1200px) {



    .h3,
    h3 {
        font-size: 1.75rem;
    }
}



@media (max-width: 768px) {
    .learnEnglish {
        font-weight: bold;
        font-size: 30px;
    }

    .anyTime {
        font-size: 30px;
        font-weight: bold;
        color: #B41B05;

    }

    .container2 {
        margin-top: 100px;
    }

    .mobileGifContainer {
        margin-top: 10px;
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }


}

.videoGif {

    height: fit-content;
    /* Adjust as necessary */
    margin-left: 165px;
    object-fit: cover;
}




/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    background-color: #eeeeea;
} */

.mx-auto-heading {
    margin-left: 100px;
    font-size: 50px;
    margin-top: 10px;
    width: fit-content;
}


.mx-auto-heading-mal {
    margin-left: 100px;
    font-size: 40px;
    margin-top: 10px;
    width: fit-content;
}



.theme-red-text {
    color: #706f6e;
    /* Example color for Anglofone text */
}


.wrapper_inside {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_inside {
    margin-bottom: 120px;
    height: 150px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    margin-left: 120px;
    position: absolute;
}

.card {
    width: 80px;
    height: auto;
    border-radius: 3rem;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    margin: 0 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: .6s cubic-bezier(.28, -0.03, 0, .99);
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
}

.card>.row {
    color: white;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
}

.card>.row>.icon {

    /* background: #223; */
    color: white;
    border-radius: 50%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}







.desc h4 {

    width: 290px;
    overflow: hidden;
    text-align: center;
    font-size: 22px;
    font-family: "Impact", sans-serif;
    text-transform: capitalize;

}

.decs_align {
    align-items: center;

    height: auto;
    display: flex;
}

input {
    display: none;
}

input:checked+label {
    width: 350px;
}

input:checked+label .description {
    opacity: 1 !important;
    transform: translateY(100px) !important;
}

.card[for="c1"] {
    background-image: url("../Assets/Images/Banner/1.jpg");
}

.card[for="c2"] {
    background-image: url('../Assets/Images/Banner/1.jpg');
}

.card[for="c3"] {
    background-image: url('../Assets/Images/Banner/1.jpg');
}

.card[for="c4"] {
    background-image: url('../Assets/Images/Banner/1.jpg');
}

.card>.row>.icon img {
    position: relative;
    height: 60px;
    /* Default size */
    width: 60px;
    /* Default size */
    border-radius: 50%;
    transition: all 0.3s ease;

    margin-left: 0px;
    /* Smooth transition for size change */
}

input:checked+label .icon img {
    margin-top: 5px !important;
    margin-left: 40px !important;
    height: 100px !important;
    /* Increased size when card is checked */
    width: 100px !important;
    /* Increased size when card is checked */
    /* Adjust to maintain alignment */
}




.wrapper_inside_l {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_inside_l {
    margin-bottom: 120px;
    height: 200px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    margin-left: 120px;
    position: absolute;
}

.card_l {
    width: 80px;
    height: auto;
    border-radius: 3rem;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    margin: 0 10px;
    display: flex;
    align-items: center;

    transition: .6s cubic-bezier(.28, -0.03, 0, .99);
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
}

.card_l>.row {
    color: white;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
}

.card_l>.row>.icon_l {

    /* background: #223; */
    color: white;
    border-radius: 50%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.desc_l h4 {

    width: 290px;

    overflow: hidden;
    text-align: center;

    font-family: "Impact", sans-serif;
    ;

}

.decs_align_l {
    align-items: center;

    height: auto;
    display: flex;
}

input {
    display: none;
}

input:checked+label {
    width: 430px;
}

input:checked+label .description {
    opacity: 1 !important;
    transform: translateY(100px) !important;
}

.card_l[for="c1"] {
    background-image: url("../Assets/Images/Banner/1.jpg");
}

.card_l[for="c2"] {
    background-image: url('../Assets/Images/Banner/1.jpg');
}

.card_l[for="c3"] {
    background-image: url('../Assets/Images/Banner/1.jpg');
}

.card_l[for="c4"] {
    background-image: url('../Assets/Images/Banner/1.jpg');
}

.card_l>.row>.icon_l img {
    position: relative;
    height: 60px;
    /* Default size */
    width: 60px;
    /* Default size */
    border-radius: 50%;
    transition: all 0.3s ease;

    margin-left: 0px;
    /* Smooth transition for size change */
}

input:checked+label .icon_l img {
    margin-top: 5px !important;
    margin-left: 40px !important;
    height: 100px !important;
    /* Increased size when card is checked */
    width: 100px !important;
    /* Increased size when card is checked */
    /* Adjust to maintain alignment */
}

@media (max-width: 1200px) {

    .videoGif {
        align-items: center;
        margin-left: 0px;
    }

    .wrapper_inside {
        height: 60vh;
    }

    .container_inside {
        height: 400px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        /* Stack cards vertically */
        justify-content: flex-start;
        align-items: center;
        /* Center cards horizontally */
        gap: 10px;
        margin-bottom: 0px;

    }

    .card {
        width: 75%;
        height: 100%;
        border-radius: 3rem;
        background-size: cover;
        cursor: pointer;
        overflow: hidden;
        margin: 0 5px;
        display: flex;
        align-items: flex-start;
        transition: height 0.6s cubic-bezier(100, -100, -100, .99);
        box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);

    }

    .card>.row {
        color: white;
        display: flex;
        flex-wrap: nowrap;
    }

    .card>.row>.icon {

        /* background: #223; */
        color: white;
        border-radius: 50%;
        width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
    }

    /* .card>.row>.description {
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        height: 300px;
        width: 150%;
        opacity: 0;
        transform: translateX(230px);
        transition-delay: .3s;
        transition: all .3s ease;
    } */

    .desc h4 {
        visibility: hidden;
        /* Hide the text by default */
        opacity: 0;
        /* Make it invisible */
        transition: opacity 0.3s ease, visibility 0.3s ease;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 135px;
        text-transform: lowercase;
        font-family: Impact, sans-serif;
        font-weight: normal;
        font-size: 17px;
        transition-delay: .3s;
        transition: all .3s ease;
        margin-bottom: 0px;
        left: 18% !important;
        top: 10% !important;
        position: relative;
    }

    input:checked+label .desc h4 {
        visibility: visible;
        /* Show the text when the input is checked */
        opacity: 1;
        /* Make it fully visible */
        transition-delay: 0.3s;
        /* Add delay to match other animations */
        text-transform: capitalize;
    }

    .desc {
        align-items: center !important;
        text-align: center;
    }

    input {
        display: none;
    }

    input:checked+label {
        height: 500px;
        width: 150%;
    }

    input:checked+label .description {
        opacity: 1 !important;
        transform: translateY(100px) !important;
    }

    .card>.row>.icon img {
        position: relative;
        height: 65px;
        /* Default size */
        width: 65px;

        /* Default size */
        border-radius: 50%;
        transition: all 0.3s ease;
        margin-top: 0px;

        margin-left: 130px;


        /* Smooth transition for size change */
    }

    input:checked+label .icon img {
        margin-top: 0px !important;
        margin-left: 50px !important;
        height: 80px !important;
        /* Increased size when card is checked */
        width: 80px !important;
        /* Increased size when card is checked */
        /* Adjust to maintain alignment */
    }

    .videoGif_l {
        align-items: center;
        margin-left: 0px;
    }

    .wrapper_inside_l {
        height: 60vh;
    }

    .container_inside_l {
        height: 40%;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        /* Stack cards vertically */
        justify-content: center;
        align-items: center;
        /* Center cards horizontally */
        gap: 10px;
        margin-bottom: 0px
    }

    .card_l {
        width: 75%;
        height: 100%;
        border-radius: 3rem;
        background-size: cover;
        cursor: pointer;
        overflow: hidden;
        margin: 0 5px;
        display: flex;
        align-items: center;
        transition: height 0.6s cubic-bezier(100, -100, -100, .99);
        box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.8);
    }

    .card_l>.row {
        color: white;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
    }

    .card_l>.row>.icon_l {

        /* background: #223; */
        color: white;
        border-radius: 50%;
        width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
    }

    /* .card>.row>.description {
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        height: 300px;
        width: 150%;
        opacity: 0;
        transform: translateX(230px);
        transition-delay: .3s;
        transition: all .3s ease;
    } */

    .decs_align_l {
        align-items: center;

        width: 220px;
        display: flex;
        padding: 0px;
    }

    .desc_l h4 {
        visibility: hidden;
        /* Hide the text by default */
        opacity: 0;
        /* Make it invisible */
        transition: opacity 0.3s ease, visibility 0.3s ease;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 220px;
        /* text-transform: lowercase; */
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-size: 17px;
        transition-delay: .3s;
        transition: all .3s ease;
        margin-top: 10px;
        left: 18% !important;
        top: 10% !important;
        position: relative;
    }

    input:checked+label .desc_l h4 {
        visibility: visible;
        /* Show the text when the input is checked */
        opacity: 1;
        /* Make it fully visible */
        transition-delay: 0.3s;
        /* Add delay to match other animations */
    }

    .desc_l {
        align-items: center !important;
        text-align: center;
    }

    input {
        display: none;
    }

    input:checked+label {
        height: 700px !important;
        width: 120%;
    }

    input:checked+label .description {
        opacity: 1 !important;
        transform: translateY(100px) !important;
    }

    .card_l>.row>.icon_l img {
        position: relative;
        height: 65px;
        /* Default size */
        width: 65px;

        /* Default size */
        border-radius: 50%;
        transition: all 0.3s ease;
        margin-left: 170px;

        /* Smooth transition for size change */
    }

    input:checked+label .icon_l img {
        margin-top: 0px !important;
        margin-left: 50px !important;
        height: 80px !important;
        /* Increased size when card is checked */
        width: 80px !important;
        /* Increased size when card is checked */
        /* Adjust to maintain alignment */
    }

    .mx-auto-heading {
        margin-left: 10px;
        font-size: 25px;
        margin-top: 20px;
    }

    h2 {
        margin-left: 0px;
        /* Align heading centrally */
        font-size: 24px;
        /* Adjust font size for mobile */
        text-align: center;
        margin-top: 20px;
        /* Add spacing above the heading */
    }

    .section-why-choose-us {
        flex-direction: row;
        /* Stack items vertically */
        height: auto;
        /* Allow height to adjust dynamically */
        /* Add spacing below heading */
    }

    .mx-auto-heading {
        font-size: 30px;
        text-align: center;
        width: 100%;

    }

    .mx-auto-heading-mal {
        
        text-align: center;
        margin-left: 10px;
        width: 100%;
        font-size: 30px;

    }

    .mainContainer_f {
        background-image: url('../Assets/Images/Banner/Foundation.jpg');
        margin-top: 90px;
        background-size: 272px;
        background-position: center;
    }

    .mainContainer_m {
        background-image: url('../Assets/Images/Banner/Mediate.jpg');
        margin-top: 90px;
        background-size: 282px;
    }

    .mainContainer_ie {
        background-image: url('../Assets/Images/Banner/IELTS.jpg');
        margin-top: 90px;
        background-size: 1500px;
    }

    .mainContainer_s {
        background-image: url('../Assets/Images/Banner/Seed.jpg');
        margin-top: 90px;
        background-size: 272px;
    }

    .mainContainer_so {
        background-image: url('../Assets/Images/Banner/Speak\ out.jpg');
        margin-top: 90px;
        background-size: 272px;
        background-position: center;
    }

    .mainContainer_i {
        background-image: url('../Assets/Images/Banner/INTERVIEW.jpg');
        margin-top: 90px;
        background-size: 1500px;
        background-position: center;
    }

    .mainContainer_p {
        background-image: url('../Assets/Images/Banner/Phonics.jpg');
        margin-top: 90px;
        background-size: 272px;
        background-position: center;
    }

    .mainContainer_t {

        background-image: url('../Assets/Images/Banner/tamil.jpg');
        margin-top: 90px;
        background-size: 272px;
        background-position: center;

    }

    .mainContainer_b {
        background-image: url('../Assets/Images/Banner/beginner.jpg');
        margin-top: 90px;
        background-size: 272px;
        background-position: center;
    }


    .mainContainer_tel {

        background-image: url('../Assets/Images/Banner/telugu.jpg');
        margin-top: 90px;
        background-size: 272px;
        background-position: center;

    }


    .mainContainer_mal {

        background-image: url('../Assets/Images/Banner/malayalam.jpg');
        margin-top: 90px;
        background-size: 272px;
        background-position: center;
    }

    .align-items-start {

        height: 390px;
        /* Sets the height of the container */
        padding: 10px;
    }

}