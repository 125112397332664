html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  width: 100%; /* Ensures no extra width is added */
}

footer .footer-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

footer .footer-links ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

footer .footer-links ul li:first-child {
    font-family: "Samsung Sharp Sans";
    font-size: 24px;
    font-weight: bold;
    line-height: 50px;
}

footer .footer-links ul li {
    color: white;
    line-height: 35px;
}

footer .footer-links ul li a {
    color: white;
    text-decoration: none;
}

.container {
  /* padding: 20px; */
  color: white;
}

.quick-links-title {
  font-family: "Samsung Sharp Sans";
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
}

.quick-links {
  margin-top: 0px; /* Moves the section upwards */
  top:-52px;
  position: relative;
}

footer .copyright {
    color: #ccc;
    margin: 0;
    text-align: center;
    padding: 0 0px 20px 0;
}

.hrline {
    color: #ccc;
    margin: 0;
    text-align: center;
    padding: 0 0px 20px 0;
}


.float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #FFF !important;
    border-radius: 50px;
    text-align: center;
    z-index: 100;
    font-size: 20px;
    padding: 5px 20px;
    text-decoration: none;
    font-weight: 500;
}

/* Float Button Styling */


  
  /* Modal Styles */
  .custom-modal-content {
    background-color: white; /* Light reddish background */
    border: 2px solid #d32f2f;
    border-radius: 20px;
    padding: 0px;
  }
 
  
  /* Modal Body */
  .custom-modal-body {
    text-align: center;
    padding: 20px;
  }
  .language-list {
    list-style: none;
    padding: 0;
  }
  /* Modal Header */
.custom-modal-header {
    position: relative; /* Enables positioning the close button */
    background-color: #d32f2f;
    color: white;
    padding: 10px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }
  
/* Close Button Styles */
.custom-close-btn {
    position: absolute;
    top: -18px;
    right: 5px;
    color: black; /* Blue color for the close icon */
    font-size: 40px; /* Icon size */
    font-weight: bold;
    background: none; /* No background */
    border: none; /* No border */
    cursor: pointer; /* Makes it clickable */
    height: 20px;
  }
  
  .custom-close-btn:focus {
    outline: none; /* No focus outline */
  }
  
  .custom-close-btn:hover {
    color: black; /* Slightly darker blue when hovered */
    transform: scale(1.1); /* Slight enlargement on hover */
    transition: all 0.2s ease-in-out; /* Smooth transition */
  }
  
  
  /* Language List */
  .language-item {
    padding: 10px;
    margin: 10px auto;
    cursor: pointer;
    border: 1px solid #d32f2f;
    border-radius: 4px;
    background-color: white;
    color: #d32f2f;
    transition: all 0.3s ease;
    max-width: 200px;
  }
  
  .language-item:hover {
    background-color: pink; /* Pink on hover */
    color: white;
  }
  
  .language-item:active {
    background-color: #d32f2f; /* Red when clicked */
    color: white;
  }
  
  /* Modal Backdrop */
  .modal-backdrop.show {
    backdrop-filter: blur(8px); /* Strong blur effect */
  }
  
  /* Center alignment for the "Branches" heading */
/* Adjust the entire branches section's position */
.branches-section {
  margin-top: -50px; /* Moves the section higher */
  position: relative;
}

/* Center and slightly shift the heading to the left */
.branches-heading {
  margin-top: 5px;
  margin-bottom: 20px;
  position: relative;
  right: -250px; /* Moves heading slightly to the left */
  font-size: 24px;
  font-weight: bold;
  color: white;
}



.branch-details {
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;
}

.branch-details li {
  line-height: 1.8;
}

.branch-name {
  font-size: 18px;
  font-family: "Samsung Sharp Sans";
  color: #fff;
  margin-bottom: 10px;
  margin-right: 60px;
}
/* Styles for branch info */
.branch-info {
  font-weight: normal;
  font-family: "Plus Jakarta Sans";
  font-size: 13px; /* Adjust the text size */
  line-height: 1.5; /* Spacing between lines */
  word-wrap: break-word; /* Ensure long words break to the next line */
  margin-bottom: 15px; /* Add spacing between each info item */
  /* Keep text readable */
  margin-right: 30px;
}

.branch-info .label {
  font-weight: normal; /* Highlight labels like "Mobile", "Address", etc. */
  margin-right: 5px; /* Add spacing between the label and the content */
  font-family: "Plus Jakarta Sans";
}

/* Address specific adjustments */
.branch-info span {
  display: inline-block;
  vertical-align: top;
  font-family: "Plus Jakarta Sans";
}

.branch-info p {
  text-align: left; /* Keep the text aligned neatly to the left */
  font-family: "Plus Jakarta Sans";
}

.detail {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.label {
  font-weight: bold;
  
  margin-right: 5px;
}


.detail-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #d32f2f; /* Red color for the icon */
}

.row .col-md-3 {
  margin-left: 10px; /* Adjusts left spacing */
}
.detail-text {
  font-size: 14px;
  font-weight: normal;
}

.courses-button {
  background: none;
  border: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: inherit; /* Use parent text color */
  padding: 0px 0px;
}
.fontint{
  font-size: 12px;
  margin-bottom: 100px;
  padding-bottom: 100px;


}

/* Style for the courses list */
.courses-list {
  list-style: none;
  margin: 0px 0 0; /* Add margin for spacing */
  padding: 0px 15px;
  background-color: inherit; /* Inherit background from parent */
}

/* Style each course item individually */
.courses-list .course-item {
  padding: 0px 0;
  color: rgb(170, 15, 15);
  background-color: inherit; /* Ensure background consistency */
}

/* Links inside the courses section */
.courses-list .course-item a {
  text-decoration: none;
  color: white;
  display: block;
}

.courses-list .course-item a:hover {
  color: rgba(255, 255, 255, 0.8); /* Slight color change on hover */
}

.quick-links .courses-list .course-item {
  margin: 0; /* Reset margin for course items */
}



/* .column.footer-links {

  padding-bottom: 0px; 
  margin-top: 0; 
  margin-bottom: 0; 
  height: fit-content;
} */

@media (max-width: 768px) {
  .footer-links {
    display: flex;
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center align sections */
  }

  .quick-links,
  .branches-section {
    width: 100%; /* Full width for each section */
    text-align: left; /* Align all branch content to the left for better readability */
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .branches-section .row {
    flex-direction: column; /* Stack branch columns */
  }

  .branches-section ul {
    width: 100%; /* Full width for branch content */
    margin-bottom: 20px; /* Add spacing between branch sections */
  }

  .branches-section ul li {
    text-align: left; /* Align branch text to the left */
    padding: 0 10px; /* Add padding for better readability */
  }

  .hrline {
    margin: 20px 0; /* Adjust spacing around the horizontal line */
  }

  .copyright {
    text-align: center;
    margin-bottom: 20px;
  }

  .float {
    position: fixed;
    bottom: 10px;
    right: 10px;
    
    
    border-radius: 50px;
    text-align: center;
    z-index: 100;
    font-size: 16px;
    padding: 2px 5px;
    text-decoration: none;
    font-weight: 100;
}

.float img {
  height: 20px ;
}



.courses-button {
  position: relative;
  left: 42%;
  text-align: center;
  align-items: center;
  justify-content: center;
}


/* Center and slightly shift the heading to the left */
.branches-heading {
  
  margin-bottom: 10px;
  position: relative;
  right: 0px; /* Moves heading slightly to the left */
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-top: 50px;
}



.branch-details {
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;
  text-align: center;
  position: relative;
}

.branch-details li {
  line-height: 1.8;
}

.branch-name {
  font-size: 18px;
  font-family: "Samsung Sharp Sans";
  color: #fff;
  margin-bottom: 10px;
  margin-right: 0px;
  text-align: center;
}
/* Styles for branch info */
.branch-info {
  font-weight: normal;
  font-family: "Samsung Sharp Sans";
  font-size: 14px; /* Adjust the text size */
  line-height: 1.5; /* Spacing between lines */
  word-wrap: break-word; /* Ensure long words break to the next line */
  margin-bottom: 15px; /* Add spacing between each info item */
  /* Keep text readable */
  margin-right: 0px;
  text-align: center;
}

.branch-info .label {
  font-weight: normal; /* Highlight labels like "Mobile", "Address", etc. */
  margin-right: 5px; /* Add spacing between the label and the content */
  font-family: "Samsung Sharp Sans";
}

/* Address specific adjustments */
.branch-info span {
  display: inline-block;
  vertical-align: top;
  font-family: "Samsung Sharp Sans";
}

.branch-info p {
  text-align: left; /* Keep the text aligned neatly to the left */
  font-family: "Samsung Sharp Sans";
}

.detail {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.label {
  font-weight: bold;
  
  margin-right: 5px;
}


 
}
